import React from 'react'
import EnLayout from '../components/en_layout'
import Header from '../components/header'
import SEO from '../components/seo'
import Container from '../components/mainContent/container'
import Estilos from '../components/mainContent/container.module.css'
import ImagenDosmilUno from '../components/imageComponents/imagenDosmilUno'

const EndosmilunoPpage = () => (
  <EnLayout>
    <SEO title='2001 Manual' />
    <Header siteTitle='OR-PRINTER Manual 2001' />

    <Container>
      <div className='row justify-content-md-center'>
        <div className='col-12 col-md-6 text-center'>
          <ImagenDosmilUno />
        </div>
        <div className='col-12 col-md-6  conmargen'>
          <h3 className={Estilos.username}>Technical specifications:</h3>
          <ul className='aplicaciones'>
            <li> Machine operated by manual lever. </li>
            <li> Power supply: 230 V. </li>
            <li> Maximum electrical power: 420 W. </li>
            <li> Maximum Pressure: 600 kgs. </li>
            <li> Maximum height of the piece: 35mm. </li>
            <li> Maximum printing area: 140 X 80 mm. </li>
            <li> Digital temperature controller P.I.D. up to 300 & deg; C. </li>
            <li> Timer controlled electric motor film advance electronic.</li>
            <li> Self-tilting head, adjustable in height and inclination. </li>
            <li> Digital counter with zero setting. </li>
            <li> Weight: 30 Kg. </li>
            <li> Dimensions: Height: 80 cms. Width: 44 cms. Depth: 46 cms. </li>
          </ul>
        </div>
      </div>
    </Container>
  </EnLayout>
)

export default EndosmilunoPpage
